import "../../styles/lightingSlider.css";
import { useEffect, useState, useRef } from "react";

const LightingSlider = ({
  step,
  min,
  max,
  value,
  onChangeValue,
  defaultLength,
}) => {
  const rangeRef = useRef();
  const [range, setRange] = useState(defaultLength);

  useEffect(() => {
    const rangeValue = parseInt(rangeRef.current.value, 10);
    setRange(rangeValue);
  }, []);

  const handleChange = (max) => (e) => {
    onChangeValue(e);
    setRange(e.target.value);

    // Adjust brightness of slider element -> need to target three.js scene (except for icons)
    const rangeInput = document.getElementById("range");
    // the filter is applied to the container
    // const container = document.getElementsByClassName("slider-container")[0];
    const container = document.getElementById("webgl-container");

    rangeInput.addEventListener("change", () => {
      container.style.filter = "brightness(" + rangeInput.value + "%)";
    });
  };

  return (
    <div className='slider-container'>
      <input
        ref={rangeRef}
        className='range-slider'
        id='range'
        type='range'
        step={step}
        min={min}
        max={max}
        value={value}
        onChange={handleChange(max)}
      />
      <span className='range-slider-value'>{range}%</span>
    </div>
  );
};

export default LightingSlider;
