import styled from "styled-components";
import AddProduct from "../../assets/add_product_icon.svg";

const StyledButton = styled.button`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  align-items: center;
  border: none;
  background-color: #fafafa;
  cursor: pointer;
  box-shadow: 2px 2px 2px #dbdbd9;
  font-size: 14px;
  opacity: 80%;
  z-index: 265;

  &:active {
    background-color: #bcd2e8;
  }
`;

const AddNewProduct = (props) => {
  return (
    <StyledButton onClick={props.onClick}>
      <img src={AddProduct} />
    </StyledButton>
  );
};

export default AddNewProduct;
