import { useState } from "react";
import styled from "styled-components";
import { initXR } from "../../threejs/helpers/AR-Helpers/androidXR";
import { RoundedPanel, SliderButton } from "./shared";

const ProductsMenu = styled.nav`
  ${({ enabled }) => (enabled ? "display: flex;" : "display: none;")}
  z-index: 350;
  position: fixed;
  bottom: 3.75rem;
  left: 1rem;
`;

const ProductList = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  padding-right: 1.1rem;
  overflow-x: scroll;
  overflow-y: hidden;
  gap: 0.4rem;
`;

const RoundSelectBtn = styled.button`
  border: 2px solid #fff;
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
  position: absolute;
  background: transparent;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`;

const ItemName = styled.p`
  ${({ enabled }) => (enabled ? "display: block;" : "display: none;")}
  position: absolute;
  bottom: 2.5rem;
  color: #fff;
  font-weight: bold;
  text-align: center;
`;

const ItemBrand = styled.p`
  ${({ enabled }) => (enabled ? "display: block;" : "display: none;")}
  position: absolute;
  top: 2.5rem;
  color: #fff;
  font-weight: bold;
`;

const ProductsSwitchPanel = ({ enabled, context, onClick }) => {
  const [selectedName, setSelectedName] = useState("Mask");

  const placeHolderArray = [
    {
      brand: "Cela",
      name: "Mask",
      icon: "/images/icons/front_mask.png",
      url: "/models/mask.glb",
    },
    {
      brand: "Cela",
      name: "Glasses",
      icon: "/images/icons/front_sunglasses.png",
      url: "/models/glasses.glb",
    },
    {
      brand: "Cela",
      name: "Web XR",
      icon: "/images/icons/front_mask.png",
      url: "/models/cela_hamburger.glb",
    },
  ];

  const availableModels = placeHolderArray.map((item, index) => {
    return (
      <div
        key={index}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={() => switchModel(item.name, item.url)}
      >
        <ItemName enabled={selectedName === item.name}>{item.name}</ItemName>
        <SliderButton
          style={{ margin: 0, display: "flex", justifyContent: "center" }}
        >
          <img
            src={item.icon}
            style={{ height: "40px", width: "40px" }}
            alt=''
          />
        </SliderButton>
        {selectedName === item.name ? <RoundSelectBtn /> : null}
        <ItemBrand enabled={selectedName === item.name}>{item.brand}</ItemBrand>
      </div>
    );
  });

  const switchModel = (name, url) => {
    setSelectedName(name);
    context.clearMainGroup();
    if (context.arView) {
      if (name === "Glasses") {
        context.renderMask = false;
        context.renderGlasses = true;
        context.loadGlasses();
      }

      if (name === "Mask") {
        context.renderMask = true;
        context.renderGlasses = false;
        context.loadMask();
      }

      if (name === "Web XR") {
        initXR(context);
      }
    } else {
      context.addModel(url, context.modelGroup, true, null, name);
    }
  };

  return (
    <ProductsMenu style={{ bottom: "5rem" }} enabled={enabled}>
      <RoundedPanel style={{ justifyContent: "flex-start" }}>
        <SliderButton onClick={onClick}>
          <img
            src={"/images/icons/switch_product_icon.svg"}
            alt='Switch Product'
          />
        </SliderButton>
        <ProductList>{availableModels}</ProductList>
      </RoundedPanel>
    </ProductsMenu>
  );
};

export default ProductsSwitchPanel;
