import styled from "styled-components";

const ModalContainer = styled.div`
  ${({ enabled }) => (enabled ? "display: flex;" : "display: none;")}
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const Modal = styled.aside`
  display: flex;
  position: absolute;
  background: linear-gradient(
    112.91deg,
    rgba(191, 191, 191, 0.255) 3.51%,
    rgba(191, 191, 191, 0) 111.71%
  );
  filter: drop-shadow(14.6469px 17.5762px 29.2937px rgba(0, 0, 0, 0.05));
  backdrop-filter: blur(40px);
  border: 2px solid #bebebe20;
  border-radius: 0.25rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 25rem;
  width: 18rem;
  color: #fff;
  text-align: center;
`;

const ModalBtn = styled.button`
  padding: 0.5rem 3rem;
  color: #fff;
  background: linear-gradient(
    201.18deg,
    rgba(112, 112, 112, 0.64) -35.98%,
    rgba(112, 112, 112, 0) 231.63%
  );
  filter: drop-shadow(14.6469px 17.5762px 29.2937px rgba(0, 0, 0, 0.05));
  backdrop-filter: blur(20.5056px);
  border: 2px solid #bebebe20;
  border-radius: 0.25rem;
`;

const BuyLinkModal = ({ url, enabled, closeModal }) => {
  const redirectHandler = () => {
    window.location.href = url;
  };

  return (
    <ModalContainer onClick={closeModal} enabled={enabled}>
      <Modal onClick={(e) => e.stopPropagation()}>
        <img
          style={{ marginBottom: "1rem" }}
          src="/images/icons/redirect_icon.svg"
          alt="Redirect Icon"
        />
        <div style={{ padding: "0rem 2rem" }}>
          <h5>You will be redirected to a new page.....</h5>
          <p>If you have not been redirected press continue.</p>
        </div>
        <ModalBtn
          style={{ marginTop: "1rem" }}
          onClick={() => console.log("clicked")}
        >
          Continue
        </ModalBtn>
      </Modal>
    </ModalContainer>
  );
};

export default BuyLinkModal;
