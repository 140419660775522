import { useEffect, useRef, useState, createContext } from "react";
import ArViewer from "./components/panels/ArViewer";
import CameraInput from "./components/inputs/CameraInput";

import styled from "styled-components";
import config from "./config";
import { initializeScene } from "./threejs";

import "./App.css";

const WebGLContainer = styled.div`
  position: absolute;
  ${({ rotate }) => (rotate ? "transform: rotateY(180deg);" : "")}
  background: linear-gradient(
    180deg,
    #8a8a8a 58.99%,
    rgba(138, 138, 138, 0.38) 69.15%
  );
`;

const UIComponentStyled = styled.div`
  position: absolute;
`;

export const AppContext = createContext({});

const App = () => {
  const [arView, setArView] = useState(config.defaults.ARView);
  const webGLContainerRef = useRef(null);
  const contextRef = useRef({
    primaryDirectionalLightContainer: {},
  });
  const context = contextRef.current;

  // Put Global Context states here
  const [sceneTitles, setSceneTitles] = useState(config.defaults.sceneTitles);
  const [currentViewer, setCurrentViewer] = useState(
    config.defaults.currentViewer
  );

  // To share variables with threejs, add them as a key/value to this object
  context.sceneTitles = sceneTitles;
  context.video = undefined;
  context.res = { res: { result: "undefined" } };
  context.arView = arView;

  useEffect(() => {
    initializeScene({
      webGLContainerRef,
      params: config.params,
      context,
      arView,
    });
  }, []);

  return (
    <AppContext.Provider
      value={{
        sceneTitles,
        setSceneTitles,
        context,
        // Viewers
        currentViewer,
        setCurrentViewer,
        //
        setArView,
        arView,
      }}
    >
      {/* Commented out cameraInput for now, for performance during development */}
      <CameraInput context={context} webGlContainer={webGLContainerRef} />
      <UIComponentStyled>
        <ArViewer />
      </UIComponentStyled>
      {/*webgl-container id - used in LightingSlider.js to adjust brightness*/}
      <WebGLContainer
        rotate={arView}
        ref={webGLContainerRef}
        id="webgl-container"
      />
    </AppContext.Provider>
  );
};

export default App;
