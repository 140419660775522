import { useState } from "react";
import styled from "styled-components";
import { RoundedPanel, SliderButton } from "./shared";
import addProductIcon from "../../assets/add_product_icon.svg";
import DeleteButton from "../buttons/DeleteButton";

const ProductsMenu = styled.nav`
  ${({ enabled }) => (enabled ? "display: flex;" : "display: none;")}
  z-index: 350;
  position: fixed;
  bottom: 3.75rem;
  left: 1rem;
`;

const ProductList = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  padding-right: 1.1rem;
  overflow-x: scroll;
  overflow-y: hidden;
  gap: 0.8rem;
`;

const SquareSelectBtn = styled.button`
  border: 2px solid #fff;
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
  position: absolute;
  background: transparent;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`;

const ItemName = styled.p`
  ${({ enabled }) => (enabled ? "display: block;" : "display: none;")}
  position: absolute;
  bottom: 2.5rem;
  color: #fff;
  font-weight: bold;
  text-align: center;
`;

const ItemBrand = styled.p`
  ${({ enabled }) => (enabled ? "display: block;" : "display: none;")}
  position: absolute;
  top: 2.5rem;
  color: #fff;
  font-weight: bold;
`;

const AddNewProductsPanel = ({ enabled, context, onClick }) => {
  const [selectedName, setSelectedName] = useState(["Mask"]);
  // const [isMaskLoaded, setIsMaskLoaded] = useState(true);
  // const [isGlassesLoaded, setIsGlassesLoaded] = useState(false);

  const placeHolderArray = [
    {
      brand: "Cela",
      name: "Mask",
      icon: "/images/icons/front_mask.png",
      url: "/models/mask.glb",
    },
    {
      brand: "Cela",
      name: "Glasses",
      icon: "/images/icons/front_sunglasses.png",
      url: "/models/glasses.glb",
    },
  ];

  const availableModels = placeHolderArray.map((item, index) => {
    return (
      <div
        key={index}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={() => addModel(item.name, item.url)}
      >
        <ItemName enabled={selectedName.includes(item.name)}>
          {item.name}
        </ItemName>
        <SliderButton
          style={{ margin: 0, display: "flex", justifyContent: "center" }}
        >
          <img
            src={item.icon}
            style={{ height: "40px", width: "40px", objectFit: "contain" }}
            alt=''
          />
        </SliderButton>
        {selectedName.includes(item.name) ? <SquareSelectBtn /> : null}
        <ItemBrand enabled={selectedName.includes(item.name)}>
          {item.brand}
        </ItemBrand>
      </div>
    );
  });

  // const addModel = (name, url) => {
  //   setSelectedName(name);
  //   context.clearMainGroup();
  //   context.addModel(url, context.modelGroup, true, null, name);
  // };

  const addModel = (name) => {
    // context.clearMainGroup();
    if (context.arView) {
      if (name === "Glasses") {
        setSelectedName([...selectedName, name]);
        // context.renderMask = false;
        context.renderGlasses = true;
        context.loadGlasses();
        // setIsGlassesLoaded(true);
      }

      if (name === "Mask") {
        setSelectedName([...selectedName, name]);
        context.renderMask = true;
        // context.renderGlasses = false;
        context.loadMask();
        // setIsMaskLoaded(true);
      }

      if (name === "Delete") {
        if (selectedName.length > 1) {
          const id = selectedName.findIndex((item) => item === name);
          const newArray = selectedName.splice(id, 1);
          setSelectedName(newArray);
          context.clearMainGroup();
        }
      }
      // } else {
      //   context.addModel(url, context.modelGroup, true, null, name);
    }
  };

  const deleteModel = (name) => {
    setSelectedName([name]);

    if (context.arView) {
      if (name === "Sun Glasses") {
        context.renderGlasses = false;
        context.clearMainGroup();
        // setIsGlassesLoaded = false;
      }
      if (name === "Face Mask") {
        context.renderMask = false;
        context.clearMainGroup();
        // setIsMaskLoaded = false;
      }
    }
  };

  return (
    <ProductsMenu style={{ bottom: "5rem" }} enabled={enabled}>
      <RoundedPanel style={{ justifyContent: "flex-start" }}>
        <SliderButton onClick={onClick}>
          <img src={addProductIcon} alt='Add Product' />
        </SliderButton>
        <ProductList>
          {availableModels}
          <DeleteButton
            onClick={() => addModel("Delete")}
            style={{ background: `rgba(149,149,149,0.8)` }}
          />
        </ProductList>
      </RoundedPanel>
    </ProductsMenu>
  );
};

export default AddNewProductsPanel;
