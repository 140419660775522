import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader.js";
import {
  normalizeModelScale,
  placeModelOnFloor,
} from "./helpers/scene-editor/models";
import * as math from "mathjs";

export const initializeScene = ({ webGLContainerRef, context }) => {
  context.scene = new THREE.Scene();
  const { scene } = context;

  let animationFrameRequestID = null;

  const fov = 75;
  const aspect = window.innerWidth / window.innerHeight; //1;  // the canvas default
  const near = 0.1;
  const far = 400;

  const camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
  camera.position.z = 395;
  context.camera = camera;

  const cameraTwo = new THREE.PerspectiveCamera(75, aspect, 0.1, 1000);
  context.cameraTwo = cameraTwo;

  const lightGroup = new THREE.Group();

  const directionalLight = new THREE.DirectionalLight(0xffffff, 4);
  lightGroup.add(directionalLight);

  const lightAmb = new THREE.AmbientLight(0x404040, 5);
  lightGroup.add(lightAmb);

  scene.add(lightGroup);

  const renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
  renderer.setSize(window.innerWidth, window.innerHeight);
  webGLContainerRef.current.appendChild(renderer.domElement);

  context.modelGroup = new THREE.Group();
  const { modelGroup } = context;
  scene.add(modelGroup);

  context.cameraControls = new OrbitControls(cameraTwo, renderer.domElement);
  const { cameraControls } = context;

  const addModelToGroup = (
    modelFilePath,
    group_,
    shouldPlaceOnFloor = false,
    modelObjectInfo = null,
    name = "",
    onLoad = () => {}
  ) => {
    const modelObject = {};
    // @todo: abstract out into a dedicated function for model loading.
    // - handle various formats internally.
    const modelFileExtension = modelFilePath.split(".").reverse()[0];
    // if (modelFileExtension === 'glb') {
    if (modelFileExtension === "glb" || modelFileExtension === "gltf") {
      const gltfLoader = new GLTFLoader();
      gltfLoader.load(
        modelFilePath,
        (gltfModel) => {
          // console.log('Finished Loading Model.');
          modelObject.model = gltfModel;
          if (shouldPlaceOnFloor) {
            group_.add(
              placeModelOnFloor(normalizeModelScale(gltfModel.scene, true))
            );
          } else {
            group_.add(normalizeModelScale(gltfModel.scene));
            gltfModel.scene.position.y += 5;
          }

          if (!!modelObjectInfo) {
            if (!context.countOfEachModelOnScene[modelObjectInfo.title]) {
              context.countOfEachModelOnScene[modelObjectInfo.title] = 1;
            } else {
              context.countOfEachModelOnScene[modelObjectInfo.title]++;
            }
            context.currentlyAttachedScene = gltfModel.scene;
            context.currentlyAttachedScene.belongsTo =
              modelObjectInfo.title +
              " " +
              context.countOfEachModelOnScene[modelObjectInfo.title];
          }

          group_.traverse((node) => {
            if (node.isMesh) {
              // @note: Lewis - this is causing all drag and drop objects to shift everytime a new one is placed
              // node.position.y = -5;
              node.name = name;
              // node.material.envMap = environmentMap;
              node.material.envMapIntensity = 5;
              node.castShadow = true;
              node.receiveShadow = false; // @todo: rethink?
            }

            if (node.isMesh && !modelObjectInfo) {
              node.layers.enable(10);
            }

            if (
              node.isMesh &&
              !!modelObjectInfo &&
              !node.alreadyAddedToDragAndDropMeshes
            ) {
              node.belongsTo =
                modelObjectInfo.title +
                " " +
                context.countOfEachModelOnScene[modelObjectInfo.title];
              // context.currentlyAttachedScene = node;
              context.dragAndDropMeshes.push(node);
              node.alreadyAddedToDragAndDropMeshes = true;
            }
          }); // @todo: might bring this OUT of this function. maybe. or if-block even.
          // // @todo: modify?

          onLoad(modelObject); // @todo: later async/await likely.
        },
        (progress) => {
          // console.log('Loading Model:', ((progress.loaded / 1024) / 1024), 'MB');
        },
        (error) => {
          console.error(error);
        }
      );
    } else if (modelFileExtension === "fbx") {
      const fbxLoader = new FBXLoader();
      fbxLoader.load(
        modelFilePath,
        (fbxModel) => {
          modelObject.model = fbxModel;

          console.log("Finished Loading Model.");
          console.log(fbxModel);

          if (fbxModel.animations.length > 0) {
            context.mixer = new THREE.AnimationMixer(fbxModel);
            // const { mixer } = context;

            context.modelAnimations = [...fbxModel.animations];
            // const { modelAnimations } = context;
          }

          group_.add(placeModelOnFloor(normalizeModelScale(fbxModel)));
          group_.traverse((node) => {
            if (node.isMesh) {
              node.material.envMapIntensity = 15;
              node.castShadow = true;
              node.receiveShadow = false;
            }
          });
          onLoad(modelObject);
        },
        (progress) => {
          // console.log('Loading Model:', ((progress.loaded / 1024) / 1024), 'MB');
        },
        (error) => {
          console.error(error);
        }
      );
    }
  };
  // Global addModelTO
  context.addModel = (
    path,
    group,
    shouldPlaceOnFloor,
    modelObjectInfo,
    name
  ) => {
    addModelToGroup(path, group, shouldPlaceOnFloor, modelObjectInfo, name);
  };

  context.clearMainGroup = () => {
    for (let i = 0; i < modelGroup.children.length; i++) {
      const obj = modelGroup.children[i];
      modelGroup.remove(obj);
    }
  };

  window.addEventListener("resize", onWindowResize, false);
  function onWindowResize() {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
    render();
  }

  var obj0 = { rot1: "undefined", rot2: "undefined", pos: "undefined" }; //'undefined' //[]
  var obj1 = { rot1: "undefined", rot2: "undefined", pos: "undefined" };

  var mask, glass;

  context.renderMask = true;
  context.renderGlasses = false;

  var glassSize;

  var objSize;

  var obj0Pos = [0, 0];
  var obj1Pos = [0, 0];

  //draw circle; color: "rgba(r,g,b,op)"

  //converting radiant to degrees

  function radToDeg(rad) {
    return rad * (180.0 / Math.PI);
  }

  //finding the angle between the line passing two points and the three xy, yz, and xz planes
  function angleFinder(p1, p2) {
    var x1 = p1.x;
    var y1 = p1.y;
    var z1 = p1.z;

    var x2 = p2.x;
    var y2 = p2.y;
    var z2 = p2.z;

    var lineVec = [x2 - x1, y2 - y1, z2 - z1];

    var xzPlnAngl = Math.asin(
      lineVec[1] /
        Math.sqrt(
          Math.pow(lineVec[0], 2) +
            Math.pow(lineVec[1], 2) +
            Math.pow(lineVec[2], 2)
        )
    );

    var xyPlnAngl = Math.asin(
      lineVec[2] /
        Math.sqrt(
          Math.pow(lineVec[0], 2) +
            Math.pow(lineVec[1], 2) +
            Math.pow(lineVec[2], 2)
        )
    );

    var yzPlnAngl = Math.asin(
      lineVec[0] /
        Math.sqrt(
          Math.pow(lineVec[0], 2) +
            Math.pow(lineVec[1], 2) +
            Math.pow(lineVec[2], 2)
        )
    );

    var degxz = radToDeg(xzPlnAngl);
    var degxy = radToDeg(xyPlnAngl);
    var degyz = radToDeg(yzPlnAngl);

    return [
      [xyPlnAngl, degxy],
      [xzPlnAngl, degxz],
      [yzPlnAngl, degyz],
    ];
  }

  //finding the scale (mutiplied by landmark coordinate) for the position of the 3d object (located n units away from the camera) with respect to the face landmark on the background (far clipping plane)

  function scaleFinder(far, objDistFromCam, fov, camAspect) {
    var farPlnLength = 2 * far * math.tan(((fov / 2) * Math.PI) / 180);

    var objPlnLength =
      2 * objDistFromCam * math.tan(((fov / 2) * Math.PI) / 180);

    var scale = [
      (camAspect * objDistFromCam * farPlnLength) / far,
      (objDistFromCam * farPlnLength) / far,
    ];

    return scale;
  }

  //adjusting mask on the face
  function maskAdjuster(
    xangl,
    yangl,
    zangl,
    xScl,
    yScl,
    zScl,
    maskZPosition,
    objSize
  ) {
    var maskTovidDist = (zScl * objSize.z) / 2 + maskZPosition;
    //console.log("glassTovidDist", maskTovidDist);
    if (xangl > (20 * Math.PI) / 180) {
      xangl = (20 * Math.PI) / 180;
    } else if (xangl < (-20 * Math.PI) / 180) {
      xangl = (-20 * Math.PI) / 180;
    }

    var newXangle = xangl / 2;

    var xDisplacement = maskTovidDist * Math.sin(yangl);
    var yDisplacement = maskTovidDist * Math.sin(xangl);

    return [xDisplacement, -yDisplacement, newXangle];
  }

  // glass adjuster
  function glassAdjuster(
    xangl,
    yangl,
    zangl,
    xScl,
    yScl,
    zScl,
    xPose,
    yPose,
    glassZPosition,
    objSize,
    camZPos,
    vidPlnLength
  ) {
    var glassTovidDist = (zScl * objSize.z) / 2 + glassZPosition + 5;
    var missXangle = Math.atan(
      (xPose * vidPlnLength * aspect) / (camZPos - glassZPosition)
    );
    var missYangle = Math.atan(
      (yPose * vidPlnLength) / (camZPos - glassZPosition)
    );

    var missXangleDis = glassTovidDist * Math.tan(missXangle);
    var missYangleDis = glassTovidDist * Math.tan(missYangle);

    if (xangl > (20 * Math.PI) / 180) {
      xangl = (20 * Math.PI) / 180;
    } else if (xangl < (-20 * Math.PI) / 180) {
      xangl = (-20 * Math.PI) / 180;
    }

    var xDisplacement = glassTovidDist * Math.sin(yangl) + missXangleDis;
    var yDisplacement = glassTovidDist * Math.sin(xangl) - missYangleDis;

    var newXangle = xangl + (20 * Math.PI) / 180; //+ missXangle;
    var newYangle = yangl / 1.3; // - missYangle;

    return [xDisplacement, -yDisplacement, newXangle, newYangle];
  }

  //euclidean distance
  function euclDist3D(x, y, width, height) {
    return Math.sqrt(
      Math.pow((x.x - y.x) * width, 2) +
        Math.pow((x.y - y.y) * height, 2) +
        Math.pow((x.z - y.z) * width, 2)
    );
  }

  function euclDist2DArr(x, y, width, height) {
    return Math.sqrt(
      Math.pow((x[0] - y[0]) * width, 2) + Math.pow((x[1] - y[1]) * height, 2)
    );
  }

  //rounding to significant figures
  function precise(x) {
    return Number.parseFloat(x).toPrecision(4);
  }

  context.changeTexture = (object, url) => {
    new THREE.TextureLoader().load(url, (texture) => {
      texture.flipY = false;
      const materialCopy = object.material.clone();
      materialCopy.map = texture;
      object.material.copy(materialCopy);
      return (object.material.needsUpdate = true);
    });
  };

  context.loadGlasses = () => {
    if (glass) {
      return modelGroup.add(glass);
    }

    const glassLoader = new GLTFLoader().setPath("models/");
    glassLoader.loadAsync("glasses.glb").then(function (gltf) {
      //gltf.scene.scale.set(0.5, 0.5, 0.5);
      glass = gltf.scene;
      glass.position.z = 0;
      glass.renderOrder = 5;
      glass.name = "glasses";

      var bboxGlass = new THREE.Box3().setFromObject(glass);
      glassSize = bboxGlass.getSize(new THREE.Vector3()); // HEREyou get the size
    });
  };

  context.loadMask = () => {
    if (mask) {
      return modelGroup.add(mask);
    }
    const MaskLoader = new GLTFLoader().setPath("models/Mask/");
    MaskLoader.load("mask.gltf", function (gltf) {
      mask = gltf.scene;
      mask.position.z = 0;
      mask.renderOrder = 1;
      mask.name = "mask";
      modelGroup.add(mask);

      var bbox = new THREE.Box3().setFromObject(mask);
      objSize = bbox.getSize(new THREE.Vector3()); // HEREyou get the size
    });
  };

  context.loadMask();
  context.loadGlasses();

  const videoTexture = new THREE.VideoTexture(context.video);

  const renderArView = (time) => {
    time *= 0.001; // convert time to seconds
    const rot = time + 0.2;

    //mask rendering info
    if (
      mask &&
      context.res.result !== undefined &&
      typeof context.res.result[0] != "undefined"
    ) {
      var objPlnLength =
        2 *
        (camera.position.z - mask.position.z) *
        math.tan(((fov / 2) * Math.PI) / 180);

      var maskScale =
        (euclDist3D(
          context.res.result[0][234],
          context.res.result[0][454],
          objPlnLength * aspect,
          objPlnLength
        ) /
          objSize.x) *
        1.15;
      obj0.rot1 = angleFinder(
        context.res.result[0][234],
        context.res.result[0][454]
      );
      obj0.rot2 = angleFinder(
        context.res.result[0][152],
        context.res.result[0][10]
      );
      obj0.pos = [
        precise(context.res.result[0][19].x),
        precise(context.res.result[0][19].y),
      ];

      if (obj0.pos != "undefined") {
        if (euclDist2DArr(obj0Pos, obj0.pos, 1, 1) > 0.0025) {
          obj0Pos = obj0.pos;
        }
      }
      var offset = maskAdjuster(
        -obj0.rot2[0][0],
        obj0.rot1[0][0],
        -obj0.rot1[1][0],
        maskScale,
        maskScale,
        maskScale / 1.7,
        mask.position.z,
        objSize
      );
    }

    //glass rendering info
    if (
      glass &&
      context.res.result !== undefined &&
      typeof context.res.result[0] != "undefined"
    ) {
      objPlnLength =
        2 *
        (camera.position.z - glass.position.z) *
        math.tan(((fov / 2) * Math.PI) / 180);

      var glassScale =
        euclDist3D(
          context.res.result[0][234],
          context.res.result[0][454],
          objPlnLength * aspect,
          objPlnLength
        ) / glassSize.x;
      obj1.rot1 = angleFinder(
        context.res.result[0][127],
        context.res.result[0][356]
      );
      obj1.rot2 = angleFinder(
        context.res.result[0][197],
        context.res.result[0][9]
      );
      obj1.pos = [context.res.result[0][195].x, context.res.result[0][195].y];

      if (obj1.pos != "undefined") {
        if (euclDist2DArr(obj1Pos, obj1.pos, 1, 1) > 0.002) {
          obj1Pos = obj1.pos;
        }
      }
      var glsOffset = glassAdjuster(
        -obj1.rot2[0][0],
        obj1.rot1[0][0],
        -obj1.rot1[1][0],
        glassScale,
        glassScale,
        glassScale / 1.7,
        obj1Pos[0] - 0.5,
        0.5 - obj1Pos[1],
        glass.position.z,
        glassSize,
        camera.position.z,
        objPlnLength
      );
    }

    if (glass && context.renderGlasses) {
      if (
        glass &&
        context.res.result !== undefined &&
        typeof context.res.result[0] != "undefined"
      ) {
        // glass.scale.set(glassDimScl[0], glassDimScl[1], glassDimScl[2]);
        glass.scale.set(glassScale, glassScale, glassScale / 1.7);
        // glass.position.z = -0.2 * glassDimScl[2] * glassSize.z;
      }

      if (
        glass &&
        typeof obj1.rot2[0][0] !== "undefined" &&
        typeof glsOffset !== "undefined"
      ) {
        glass.rotation.x = glsOffset[2];
      } //-obj1.rot2[0][0];}////glsOffset[2]}
      else if (glass && typeof obj1.rot2[2][0] == "undefined") {
        glass.rotation.x = rot;
      }

      if (
        glass &&
        typeof obj1.rot1[0][0] !== "undefined" &&
        typeof glsOffset !== "undefined"
      ) {
        glass.rotation.y = glsOffset[3];
      } //glsOffset[3];}//obj1.rot1[0][0];}
      else if (glass && typeof obj1.rot1[0][0] == "undefined") {
        glass.rotation.y = rot;
      }

      if (glass && typeof obj1.rot1[1][0] !== "undefined") {
        glass.rotation.z = -obj1.rot1[1][0];
      } else if (glass && typeof obj1.rot1[1][0] == "undefined") {
        glass.rotation.z = rot;
      }

      if (
        glass &&
        typeof obj1Pos !== "undefined" &&
        typeof glsOffset !== "undefined"
      ) {
        glass.position.x =
          (obj1Pos[0] - 0.5) *
            scaleFinder(
              far,
              camera.position.z - glass.position.z,
              fov,
              aspect
            )[0] -
          glsOffset[0];
      } //3.07;}
      else if (glass && typeof obj1Pos == "undefined") {
        glass.position.x = 0;
      }

      if (
        glass &&
        typeof obj1Pos !== "undefined" &&
        typeof glsOffset !== "undefined"
      ) {
        glass.position.y =
          (0.5 - obj1Pos[1]) *
            scaleFinder(
              far,
              camera.position.z - glass.position.z,
              fov,
              aspect
            )[1] -
          glsOffset[1];
      } //3.07;}
      else if (glass && typeof obj1Pos == "undefined") {
        glass.position.y = 0;
      }
      var offset = maskAdjuster(
        -obj0.rot2[0][0],
        obj0.rot1[0][0],
        -obj0.rot1[1][0],
        maskScale,
        maskScale,
        maskScale / 1.7,
        mask.position.z,
        objSize
      );
    }

    if (glass && typeof glassScale !== "undefined") {
      glass.position.z = 0;
    } //-(glassSize.z * glassScale)/2}//3.07;}
    else if (glass && typeof glassScale == "undefined") {
      glass.position.z = 0;
    }

    //rendered mask with new adjustment (offset)
    if (mask && context.renderMask) {
      if (
        mask &&
        typeof obj0Pos !== "undefined" &&
        typeof offset !== "undefined"
      ) {
        mask.position.x =
          (obj0Pos[0] - 0.5) *
            scaleFinder(
              far,
              camera.position.z - mask.position.z,
              fov,
              aspect
            )[0] -
          offset[0];
      } //obj0.pos[0]
      else if (
        mask &&
        (typeof obj0Pos == "undefined" || typeof offset == "undefined")
      ) {
        mask.position.x = 0;
      }

      if (
        mask &&
        typeof obj0Pos !== "undefined" &&
        typeof offset !== "undefined"
      ) {
        mask.position.y =
          (0.5 - obj0Pos[1]) *
            scaleFinder(
              far,
              camera.position.z - mask.position.z,
              fov,
              aspect
            )[1] -
          offset[1];
      } //obj0.pos[1]
      else if (
        mask &&
        (typeof obj0Pos == "undefined" || typeof offset == "undefined")
      ) {
        mask.position.y = 0;
      }

      if (mask && typeof obj0.rot1[0][0] !== "undefined") {
        mask.rotation.y = obj0.rot1[0][0];
      } else if (mask && typeof obj0.rot1[0][0] == "undefined") {
        mask.rotation.y = rot;
      }

      if (mask && typeof obj0.rot1[1][0] !== "undefined") {
        mask.rotation.z = -obj0.rot1[1][0];
      } else if (mask && typeof obj0.rot1[1][0] == "undefined") {
        mask.rotation.z = rot;
      }

      if (
        mask &&
        typeof obj0.rot2[0][0] !== "undefined" &&
        typeof offset !== "undefined"
      ) {
        mask.rotation.x = offset[2];
      } //-obj0.rot2[0][0];} console.log("xot", -obj0.rot2[0][0])
      else if (mask && typeof obj0.rot2[2][0] == "undefined") {
        mask.rotation.x = rot;
      }

      if (
        mask &&
        context.res.result !== undefined &&
        typeof context.res.result[0] != "undefined"
      ) {
        mask.scale.set(maskScale, maskScale, maskScale / 1.7);
      }
    }

    if (
      context.video &&
      context.video.readyState === context.video.HAVE_ENOUGH_DATA
    ) {
      videoTexture.needsUpdate = true;
      scene.background = videoTexture;
    }
  };

  const animate = function (time) {
    if (context.arView) {
      cameraTwo.position.copy(camera.position);
      renderArView(time);
      renderer.render(scene, camera);
    } else {
      if (scene.background !== null) {
        scene.background = null;
      }
      cameraControls.enabled = true;
      renderer.render(scene, cameraTwo);
    }

    const fps = 60;
    setTimeout(function () {
      animationFrameRequestID = requestAnimationFrame(animate);
      context.animationFrameRequestID = animationFrameRequestID;
    }, 1000 / fps);
  };

  function render() {
    renderer.render(scene, camera);
  }

  animate();

  const webGLContainerRefToDestroy = webGLContainerRef.current;

  return () => {
    cancelAnimationFrame(animationFrameRequestID);
    webGLContainerRefToDestroy.removeChild(renderer.domElement);
  };
};
