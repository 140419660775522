import styled from "styled-components";

const Input = styled.input`
  -webkit-appearance: none;
  appearance: none;
  height: 33px;
  width: 70px;
  border-radius: 16px;
  background: radial-gradient(
      circle 12px,
      #072b62cc 100%,
      transparent calc(100% + 1px)
    )
    #ccc -16px;
  transition: 0.3s ease-in-out;
  transform: rotate(90deg);

  &::before {
    content: "3D";
    color: white;
    font: bold 12px/32px Lato;
    left: 0rem;
    position: absolute;
    padding: 0 0.7rem 0 0.7rem;
    transform: rotate(-90deg);
    transition: 0.3s ease-in-out;
  }

  &:checked {
    background-position: 16px;
    padding-left: 8px;
  }

  &:after {
    content: "AR";
    position: absolute;
    left: 2.75rem;
    color: #fff;
    right: initial;
    transform: rotate(-90deg);
    transition: 0.3s ease-in-out;
    padding-bottom: 2rrem 1rem 1rem 1rem;
    font: bold 12px/32px Lato;
  }
`;

const ToggleDiv = styled.div`
  opacity: 80%;
`;

const ToggleInput = ({ onChange, value }) => {
  return (
    <ToggleDiv>
      <Input
        type='checkbox'
        checked={value}
        onChange={(e) => onChange(e.target.checked)}
      />
    </ToggleDiv>
  );
};

export default ToggleInput;
