import styled from "styled-components";

const StyledButton = styled.button`
  display: block;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  align-items: center;
  border: none;
  background-color: #fafafa;
  cursor: pointer;
  box-shadow: 2px 2px 2px #dbdbd9;
  font-size: 16px;
  color: #072b62;
  opacity: 80%;
  z-index: 265;
  margin-right: 1rem;

  &:active {
    background-color: #bcd2e8;
  }
`;
const StyledSpan = styled.span`
  background-color: transparent;
`;

const HelpIcons = () => {
  return (
    <StyledButton>
      <StyledSpan>?</StyledSpan>
    </StyledButton>
  );
};

export default HelpIcons;
