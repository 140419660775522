import styled from "styled-components";
import LightingOption from "../../assets/lighting_icon.svg";

const StyledButton = styled.button`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  align-items: center;
  border: none;
  background-color: #fafafa;
  cursor: pointer;
  box-shadow: 2px 2px 2px #dbdbd9;
  font-size: 14px;
  opacity: 80%;
  z-index: 265;
  margin-top: 15px;

  &:active {
    background-color: #bcd2e8;
  }
`;

const Lighting = (props) => {
  return (
    <StyledButton onClick={props.onClick}>
      <img src={LightingOption} alt='Lighting Slider' />
    </StyledButton>
  );
};

export default Lighting;
