import styled from "styled-components";
import productRedirect from "../../assets/product_redirect_icon.svg";

const StyledLongButton = styled.button`
  height: 70px;
  width: 36px;
  border-radius: 100px;
  border: none;
  z-index: 265;
  background-color: #fcfcfc;
  cursor: pointer;
  box-shadow: 2px 2px 2px #dbdbd9;
  opacity: 80%;
  margin-right: 1rem;
  position: fixed;
  right: 0rem;
  top: 25.5rem;

  s &:active {
    background-color: #bcd2e8;
  }
`;

const BuyNowPage = (props) => {
  return (
    <StyledLongButton onClick={props.onClick}>
      <img src={productRedirect} alt='Buy Link' />
    </StyledLongButton>
  );
};

export default BuyNowPage;
