import { useRef, useEffect } from "react";
import { FaceMesh } from "@mediapipe/face_mesh";
import { Camera } from "@mediapipe/camera_utils";

const CameraInput = ({ context }) => {
  const webcamRef = useRef(null);
  var width = window.innerWidth;
  var height = window.innerHeight;

  function onResults(results) {
    if (results.multiFaceLandmarks) {
      context.res = { result: results.multiFaceLandmarks };
    }
  }

  useEffect(() => {
    webcamRef.current.autoplay = true;
    const faceMesh = new FaceMesh({
      locateFile: (file) => {
        return `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`;
      },
    });
    faceMesh.setOptions({
      enableFaceGeometry: true,
      maxNumFaces: 1,
      minDetectionConfidence: 0.5,
      minTrackingConfidence: 0.5,
    });

    faceMesh.onResults(onResults);
    const cam = new Camera(webcamRef.current, {
      onFrame: async () => {
        await faceMesh.send({ image: webcamRef.current });
      },
      // Works for demo -> Needs to be resolved
      width: height,
      height: width,
    });
    cam.start();
    context.cam = cam;
  }, []);

  useEffect(() => {
    context.video = webcamRef.current;
  }, [context.arView]);

  return (
    <video
      ref={webcamRef}
      crossOrigin='anonymous'
      playsInline={true}
      style={{
        position: "absolute",
        display: "none",
      }}
      id='video'
    />
  );
};

export default CameraInput;
