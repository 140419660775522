import { WebXRButton } from "./helpers/util/webxr-button";
import { Scene } from "./helpers/render/scenes/scene";
import { Renderer, createWebGLContext } from "./helpers/render/core/renderer";
import { Node } from "./helpers/render/core/node";
import { Gltf2Node } from "./helpers/render/nodes/gltf2";
import { DropShadowNode } from "./helpers/render/nodes/drop-shadow";
import { vec3 } from "./helpers/render/math/gl-matrix";

// XR globals.
let xrButton = null;
let xrRefSpace = null;
let xrViewerSpace = null;
let xrHitTestSource = null;
const { XRWebGLLayer } = window;

const btn = document.createElement("button");
btn.id = "btn";
btn.innerHTML = "Enable WebAR Here";
btn.style.zIndex = 500;
btn.style.position = "absolute";
btn.style.left = "22rem";
btn.style.top = "25rem";
var bt = document.getElementById("btn");
// WebGL scene globals.
let gl = null;
let renderer = null;
let scene = new Scene();
scene.enableStats(false);

let arObject = new Node();
arObject.visible = false;
scene.addNode(arObject);

//let flower = new Gltf2Node({url: 'media/gltf/sunflower/sunflower.gltf'});
//arObject.addNode(flower);

let reticle = new Gltf2Node({ url: "media/gltf/reticle/reticle.gltf" });
reticle.visible = false;
scene.addNode(reticle);
let reticleHitTestResult = null;

// Having a really simple drop shadow underneath an object helps ground
// it in the world without adding much complexity.
let shadow = new DropShadowNode();
vec3.set(shadow.scale, 0.15, 0.15, 0.15);
arObject.addNode(shadow);

//const MAX_FLOWERS = 30;
//let flowers = [];

// Ensure the background is transparent for AR.
scene.clear = false;

export function initXR(context) {
  context.cam.stop();
  xrButton = new WebXRButton({
    onRequestSession: onRequestSession,
    onEndSession: onEndSession,
    textEnterXRTitle: "Enable AR",
    color: "rgb(255,255,255)",
    domElement: bt,
    textXRNotFoundTitle: "AR NOT FOUND",
    textExitXRTitle: "EXIT  AR",
  });
  cancelAnimationFrame(context.cancelAnimationFrameID);
  document.body.appendChild(xrButton.domElement);

  if (navigator.xr) {
    navigator.xr.isSessionSupported("immersive-ar").then((supported) => {
      xrButton.enabled = supported;
    });
  }
}

function onRequestSession() {
  return navigator.xr
    .requestSession("immersive-ar", {
      requiredFeatures: ["local", "hit-test", "anchors"],
    })
    .then((session) => {
      xrButton.setSession(session);
      onSessionStarted(session);
    });
}

function onSessionStarted(session) {
  session.addEventListener("end", onSessionEnded);
  session.addEventListener("select", onSelect);

  if (!gl) {
    gl = createWebGLContext({
      xrCompatible: true,
    });

    renderer = new Renderer(gl);

    scene.setRenderer(renderer);
  }

  session.updateRenderState({
    baseLayer: new XRWebGLLayer(session, gl),
  });

  // In this sample we want to cast a ray straight out from the viewer's
  // position and render a reticle where it intersects with a real world
  // surface. To do this we first get the viewer space, then create a
  // hitTestSource that tracks it.
  session.requestReferenceSpace("viewer").then((refSpace) => {
    xrViewerSpace = refSpace;
    session
      .requestHitTestSource({ space: xrViewerSpace })
      .then((hitTestSource) => {
        xrHitTestSource = hitTestSource;
      });
  });

  session.requestReferenceSpace("local").then((refSpace) => {
    xrRefSpace = refSpace;

    session.requestAnimationFrame(onXRFrame);
  });
}

function onEndSession(session) {
  anchoredObjects.clear();
  xrHitTestSource.cancel();
  xrHitTestSource = null;
  session.end();
}

function onSessionEnded(event) {
  xrButton.setSession(null);
}

const MAX_ANCHORED_OBJECTS = 30;
let anchoredObjects = [];
function addAnchoredObjectsToScene(anchor) {
  let table = new Gltf2Node({
    url: "models/Mask/mask.gltf",
  }); //suntable/suntable.gltf //'media/gltf/headset/headset.gltf'//Rayban/ray_ban_1x1.gltf//
  scene.addNode(table);
  anchoredObjects.push({
    anchoredObject: table,
    anchor: anchor,
  });

  // For performance reasons if we add too many objects start
  // removing the oldest ones to keep the scene complexity
  // from growing too much.
  if (anchoredObjects.length > MAX_ANCHORED_OBJECTS) {
    let objectToRemove = anchoredObjects.shift();
    scene.removeNode(objectToRemove.anchoredObject);
    objectToRemove.anchor.delete();
  }
}

let rayOrigin = vec3.create();
let rayDirection = vec3.create();
function onSelect(event) {
  if (reticle.visible) {
    // Create an anchor.
    reticleHitTestResult.createAnchor().then(
      (anchor) => {
        addAnchoredObjectsToScene(anchor);
      },
      (error) => {
        console.error("Could not create anchor: " + error);
      }
    );
  }
}

// Called every time a XRSession requests that a new frame be drawn.
function onXRFrame(t, frame) {
  let session = frame.session;
  let pose = frame.getViewerPose(xrRefSpace);

  reticle.visible = false;

  // If we have a hit test source, get its results for the frame
  // and use the pose to display a reticle in the scene.
  if (xrHitTestSource && pose) {
    let hitTestResults = frame.getHitTestResults(xrHitTestSource);
    if (hitTestResults.length > 0) {
      let pose = hitTestResults[0].getPose(xrRefSpace);
      reticle.visible = true;
      reticle.matrix = pose.transform.matrix;
      reticleHitTestResult = hitTestResults[0];
    }
  }

  for (const { anchoredObject, anchor } of anchoredObjects) {
    // only update the object's position if it's still in the list
    // of frame.trackedAnchors
    if (!frame.trackedAnchors.has(anchor)) {
      continue;
    }
    const anchorPose = frame.getPose(anchor.anchorSpace, xrRefSpace);
    anchoredObject.matrix = anchorPose.transform.matrix;
  }

  scene.startFrame();

  session.requestAnimationFrame(onXRFrame);

  scene.drawXRFrame(frame, pose);

  scene.endFrame();
}
