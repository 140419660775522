import { useContext, useState, useReducer } from "react";
import { AppContext } from "../../App";
import AddNewProduct from "../buttons/AddNewProduct";
import BuyNowPage from "../buttons/BuyNowPage";
import CustomizeProduct from "../buttons/CustomizeProduct";
import Lighting from "../buttons/Lighting";
import HelpIcon from "../buttons/HelpIcon";
import SwitchProducts from "../buttons/SwitchProducts";
import ToggleInput from "../inputs/ToggleInput";
import DeleteButton from "../buttons/DeleteButton";
import LightingSliderPanel from "./LightingSliderPanel";
import ProductsSwitchPanel from "./ProductsSwitchPanel";
import * as THREE from "three";

import styled from "styled-components";
import CustomizationPanel from "./CustomizationPanel";
import BuyLinkModal from "../modals/BuyLinkModal";
import AddNewProductsPanel from "./AddNewProductsPanel";

const MainPageContainer = styled.div`
  height: 100vh;
  width: 100vw;
  margin-top: 3.75rem;
  ${({ modalEnabled }) =>
    modalEnabled ? "background: rgba(0, 0, 0, 0.2);" : "initial;"}
`;

const StyledBottomLeftDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 15px;
`;

const LowerComponents = styled.div`
  ${({ enabled }) => (enabled ? "display: flex;" : "display: none;")}
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 175px;
`;

const UpperComponents = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledToggleDiv = styled.div`
  z-index: 265;
`;

const LightingPanel = styled.div`
  ${({ enabled }) => (enabled ? "display: flex;" : "display: none;")}
  justify-content: center;
  align-items: center;
  transition: all 1s ease-out;
`;

const reducer = (state, action) => {
  switch (action.type) {
    case "lighting":
      return {
        ...state,
        mainActive: !state.mainActive,
        lightingPanel: action.payload,
      };
    case "products":
      return {
        ...state,
        mainActive: !state.mainActive,
        productsPanel: action.payload,
      };
    case "customize":
      return {
        ...state,
        mainActive: !state.mainActive,
        customizePanel: action.payload,
      };
    case "add":
      return {
        ...state,
        mainActive: !state.mainActive,
        addPanel: action.payload,
      };
    default:
      return state;
  }
};

const ArViewer = () => {
  const { context, arView, setArView } = useContext(AppContext);
  const [
    { lightingPanel, productsPanel, customizePanel, addPanel, mainActive },
    dispatch,
  ] = useReducer(reducer, {
    mainActive: true,
    lightingPanel: false,
    productsPanel: false,
    customizePanel: false,
    addPanel: false,
  });
  const [modalEnabled, setModalEnabled] = useState(false);

  return (
    <MainPageContainer modalEnabled={modalEnabled}>
      <UpperComponents>
        <StyledToggleDiv>
          <ToggleInput
            value={arView}
            onChange={(e) => {
              if (!e) {
                const bbox = new THREE.Box3().setFromObject(
                  context.modelGroup.children[0]
                );
                bbox.getCenter(context.cameraControls.target);
                context.cameraControls.update();

                context.cam.stop();
              } else {
                context.cam.start();
              }
              setArView(e);
            }}
          />
        </StyledToggleDiv>
        <HelpIcon />
      </UpperComponents>
      <LowerComponents enabled={mainActive}>
        <StyledBottomLeftDiv>
          <AddNewProduct
            onClick={() => dispatch({ type: "add", payload: !addPanel })}
          />
          <SwitchProducts
            onClick={() =>
              dispatch({ type: "products", payload: !productsPanel })
            }
          />
          <Lighting
            onClick={() =>
              dispatch({ type: "lighting", payload: !lightingPanel })
            }
          />
          <CustomizeProduct
            onClick={() =>
              dispatch({ type: "customize", payload: !customizePanel })
            }
          />
        </StyledBottomLeftDiv>
      </LowerComponents>
      <BuyNowPage onClick={() => setModalEnabled(!modalEnabled)} />
      <BuyLinkModal
        enabled={modalEnabled}
        closeModal={() => setModalEnabled(false)}
      />
      <LightingPanel enabled={lightingPanel}>
        <LightingSliderPanel
          onClick={() =>
            dispatch({ type: "lighting", payload: !lightingPanel })
          }
        />
      </LightingPanel>
      <ProductsSwitchPanel
        enabled={productsPanel}
        context={context}
        onClick={() => dispatch({ type: "products", payload: !productsPanel })}
      />
      <CustomizationPanel
        enabled={customizePanel}
        context={context}
        onClick={() =>
          dispatch({ type: "customize", payload: !customizePanel })
        }
      />
      <AddNewProductsPanel
        enabled={addPanel}
        context={context}
        onClick={() => dispatch({ type: "add", payload: !addPanel })}
      />
    </MainPageContainer>
  );
};

export default ArViewer;
