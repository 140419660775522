export default {
  defaults: {
    sceneTitles: ["Cela Black", "Cela White"],
    ARView: true,
    video: null,
  },
  params: {
    modelFilePath: "models/ShoeG400.glb",
  },
};
