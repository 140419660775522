import { useState } from "react";
import styled from "styled-components";
import LightingSlider from "./LightingSlider";
import { RoundedPanel, SliderButton } from "./shared";
import LightIcon from "../../assets/lighting_icon_bigger.svg";
import "../../styles/lightingSlider.css";

const StyledSliderDiv = styled.div`
  width: 100%;
`;

const LightingSliderPanel = (props) => {
  const [rangeValue, setRangeValue] = useState(10);

  const onChangeSlider = (e) => {
    setRangeValue(parseInt(e.target.value, 10));
  };
  return (
    <RoundedPanel>
      {/* <Slider tooltip={false} /> */}
      <SliderButton onClick={props.onClick}>
        {/* <img src={upArrow} /> */}
        <img src={LightIcon} alt='Lighting Button' />
      </SliderButton>
      <StyledSliderDiv>
        <LightingSlider
          min={0}
          max={100}
          step={1}
          value={rangeValue}
          onChangeValue={onChangeSlider}
          defaultLength={40}
        />
      </StyledSliderDiv>
    </RoundedPanel>
  );
};

export default LightingSliderPanel;
