import styled from "styled-components";

export const RoundedPanel = styled.div`
  height: 2.8rem;
  width: 90%;
  border-radius: 1.875rem;
  border: none;
  background-color: rgba(255, 255, 255, 0.6);
  box-shadow: 2px 2px 2px #dbdbd9;
  position: fixed;
  bottom: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 265;
`;

export const SliderButton = styled.button`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: none;
  background-color: grey;
  cursor: pointer;
  margin-left: 5px;
  opacity: 70%;
  color: white;
  box-shadow: 2px 2px 2px grey;
`;
