import { useState } from "react";
import styled from "styled-components";
import { RoundedPanel, SliderButton } from "./shared";

const ProductsMenu = styled.nav`
  ${({ enabled }) => (enabled ? "display: flex;" : "display: none;")}
  z-index: 350;
  position: fixed;
  bottom: 3.75rem;
  left: 1rem;
`;

const ProductList = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  padding-right: 1.1rem;
  overflow-x: scroll;
  overflow-y: hidden;
  gap: 0.4rem;
`;

const RoundSelectBtn = styled.button`
  border: 2px solid #fff;
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
  position: absolute;
  background: transparent;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`;

// TODO: Might need this for later
// const ItemName = styled.p`
// ${({ enabled }) => (enabled ? "display: block;" : "display: none;")}}
//   position: absolute;
//   bottom: 2.5rem;
//   color: #fff;
//   font-weight: bold;
//   text-align: center;
// `;

const ItemBrand = styled.p`
  ${({ enabled }) => (enabled ? "display: block;" : "display: none;")}
  position: absolute;
  top: 2.5rem;
  color: #fff;
  font-weight: bold;
`;

const CustomizationPanel = ({ enabled, onClick, context }) => {
  const [selectedName, setSelectedName] = useState("Flowers");

  const placeHolderArray = [
    {
      name: "Mask Fabric",
      icon: "Flowers",
      image: "/images/icons/libby_icon.png",
      url: "/textures/flowers_texture.png",
    },
    {
      name: "Mask Fabric",
      icon: "Black",
      image: "/images/icons/black_icon.png",
      url: "/textures/black_texture.png",
    },
  ];

  const changeTexture = (name, url) => {
    setSelectedName(name);

    const scene = context.scene;

    const object = scene.getObjectByName("mask");

    context.changeTexture(object.children[0], url);
  };

  const availableModels = placeHolderArray.map((item, index) => {
    return (
      <div
        key={index}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={() => changeTexture(item.name, item.url)}
      >
        <SliderButton
          style={{ margin: 0, display: "flex", justifyContent: "center" }}
        >
          <img
            style={{ height: "40px", width: "40px" }}
            src={item.image}
            alt=""
          />
        </SliderButton>
        {item.icon === selectedName ? <RoundSelectBtn /> : null}
        <ItemBrand enabled={selectedName === item.icon}>{item.icon}</ItemBrand>
      </div>
    );
  });

  return (
    <ProductsMenu enabled={enabled}>
      <RoundedPanel style={{ justifyContent: "flex-start" }}>
        <SliderButton onClick={onClick}>
          <img
            src={"/images/icons/customization_icon.svg"}
            alt="Switch Product"
          />
        </SliderButton>
        <ProductList>{availableModels}</ProductList>
      </RoundedPanel>
    </ProductsMenu>
  );
};

export default CustomizationPanel;
